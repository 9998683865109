@mixin panel-format($opacity:1) {
  background: $panel-background;
  border-radius: 8px;
  border: solid 2px $panel-border;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

@mixin status-position() {
  margin-left: 64px;
  margin-right: 2px;
  padding-left: 8px;
  margin-bottom: 8px;
}

@mixin avatar-radius() {
  border-radius: 4px;
  background: transparent no-repeat;
  background-position: 50%;
  background-clip: padding-box;
  border: solid 2px $panel-border;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
}

